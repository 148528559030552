<template>
  <div class="content">
    <Sticky :offset-top="0" class="top_bar">
      <JNav :title="$t('Cart.text.one')" :leftArrow="false" />
      <div class="money_info_wrap">
        <div class="money_content">
          <div class="left money_item">
            <div class="item_content">
              <div class="title">{{ $t("newAdd.blance") }}</div>
              <div class="data">
                {{ $money(userInfo.money || 0, false) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
    <van-tabs ref="tabWrap" v-model="tabActive" @change="tabChange" sticky :offset-top="tabTop" color="var(--theme)"
      title-inactive-color="var(--gray1)" title-active-color="var(--theme)">
      <van-tab v-for="(t_item, t_index) in statusData" :title="t_item.title" :name="t_item.value" :key="t_index">
        <List url="/lottery/order" :initParams="{ status: t_item.value }" :nullImg="require('@/assets/Img/null/null.png')"
          ref="listRef">
          <template #item="{ data: item, index }">
            <!-- <div class="nomore" v-if="!newList.length && !listLoading">
            <img src="../../assets/Img/null/null.png" alt="" />
          </div> -->
            <div class="goods" :style="index == 0 ? 'margin-top: calc(8rem / 16)' : ''" @click="toDetails(item)">
              <div class="good_content">
                <!-- <div class="top">
              <div class="price">{{ item.total_price }}</div>
              <div class="shop_name" v-if="item.status">
                {{ $t("newAdd.Transaction") }}&nbsp;{{
                  item.status == 1
                    ? "Frozen"
                    : item.status == 2
                    ? "Done"
                    : "Canceled"
                }}
              </div>
              <div
                class="tobuy"
                v-if="!item.status"
                @click="toPay(item, index)"
              >
                <div v-if="buyLoading == index" class="loading">
                  <van-loading size="24" />
                </div>
                <span v-else>{{ $t("newAdd.ImmediatePayment") }}</span>
              </div>
            </div> -->
                <div class="bottom">
                  <div class="left" @click="toShoppingDetail(item)">
                    <img v-if="merchants.find((mitem) => mitem.type === item.type)" :src="merchants.find((mitem) => mitem.type === item.type).picture
                      " alt="" />
                  </div>
                  <div class="right">
                    <div class="title">
                      <span class="label">{{ $t("orderDetails.title.expect") }}:&nbsp;</span>
                      <span class="value">{{ item.expect }}</span>
                    </div>
                    <div class="title">
                      <span class="label">{{ $t("order.typeTitle") }}:&nbsp;</span>
                      <span class="value">{{ item.typeCn }}</span>
                    </div>
                    <div class="price">
                      <span class="label">{{ $t("order.totalPrice") }}:&nbsp;</span>
                      <span class="value">{{ item.totalMoney }}</span>
                      <!-- <span>{{ `${$t("order.winPrice")}: ${item.winMoney}` }}</span> -->
                    </div>
                    <div class="win">
                      <span class="label">{{ $t("orderDetails.title.inType") }}: &nbsp;</span>
                      <span class="value">{{ item.code }}</span>
                    </div>
                    <div class="win" v-if="item.status == '已开奖'">
                      <span class="label">{{ $t("order.winPrice") }}: &nbsp;</span>
                      <span class="value">{{ item.winMoney }}</span>
                    </div>
                    <div class="status">
                      <span class="label">{{ $t("order.status") }}:&nbsp;</span>
                      <span class="ball_wrap" v-if="item.status == '已开奖'">
                        <div class="ball" v-for="(item, index) in item?.open?.dwd" :key="index">{{ item }}</div>
                      </span>
                      <span class="value" v-else>{{ getStatus(item) }}</span>
                    </div>
                    <div class="time">{{ dateEvent(item.indate) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </List>
      </van-tab>
    </van-tabs>
    <!-- <div class="hint">{{ $t("newAdd.provided") }}</div> -->
    <!-- 列表 -->
    <div class="wrap">
      <!-- <van-list v-model="loading" :finished="finished" :finished-text="$t('Hint.notMore')" :loading-text="$t('Hint.loading')"
        @load="getTaskOrder">
        <div class="nomore" v-if="!newList.length && !listLoading">
          <img src="../../assets/Img/null/null.png" alt="" />
        </div>
        <div class="goods" v-for="(item, index) in newList.filter((a) => a.status != 5)" :key="index"
          :style="index == 0 ? 'margin-top: calc(8rem / 16)' : ''" @click="toDetails(item)">
          <div class="good_content">
            <div class="bottom">
              <div class="left" @click="toShoppingDetail(item)">
                <img v-if="merchants.find((mitem) => mitem.type === item.type)" :src="merchants.find((mitem) => mitem.type === item.type).picture
                  " alt="" />
              </div>
              <div class="right">
                <div class="title">
                  <span class="label">{{ $t("orderDetails.title.expect") }}:&nbsp;</span>
                  <span class="value">{{ item.expect }}</span>
                </div>
                <div class="title">
                  <span class="label">{{ $t("order.typeTitle") }}:&nbsp;</span>
                  <span class="value">{{ item.typeCn }}</span>
                </div>
                <div class="price">
                  <span class="label">{{ $t("order.totalPrice") }}:&nbsp;</span>
                  <span class="value">{{ item.totalMoney }}</span>
                </div>
                <div class="win">
                  <span class="label">{{ $t("orderDetails.title.inType") }}: &nbsp;</span>
                  <span class="value">{{ item.code }}</span>
                </div>
                <div class="win">
                  <span class="label">{{ $t("order.winPrice") }}: &nbsp;</span>
                  <span class="value">{{ item.winMoney }}</span>
                </div>
                <div class="status">
                  <span class="label">{{ $t("order.status") }}:&nbsp;</span>
                  <span class="ball_wrap" v-if="item.status == '已开奖'">
                    <div class="ball" v-for="(item, index) in item?.open?.dwd" :key="index">{{ item }}</div>
                  </span>
                  <span class="value" v-else>{{ getStatus(item) }}</span>
                </div>
                <div class="time">{{ dateEvent(item.indate) }}</div>
              </div>
            </div>
          </div>
        </div>
      </van-list> -->
    </div>
    <!-- 弹窗类 -->
    <div class="buy-box" v-if="isBuyBoxShow">
      <div class="cont">
        <div>
          <div class="goodsInfo">
            <div class="close" @click="closeBuy">
              <img src="../../assets/icon/close.png" alt="" />
            </div>
            <p>{{ $t("Cart.zzInfo.buyIng") }}······</p>
          </div>
          <div>
            <van-progress :percentage="percentage" stroke-width="20" />
          </div>
          <span class="space-text">{{
            $t("Cart.zzInfo.buySucceedFindDT")
          }}</span>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <div class="buy-fail" v-if="isBuyFailShow">
      <div class="cont">
        <div>
          <p>{{ $t("Cart.zzInfo.buyFailed") }}</p>
          <p>{{ $t("Cart.zzInfo.notLevel") }}</p>
          <p v-if="level == 2">{{ $t("Cart.zzInfo.buyJurisdiction1") }}</p>
          <p v-if="level == 3">{{ $t("Cart.zzInfo.buyJurisdiction2") }}</p>
          <p v-if="level == 4">{{ $t("Cart.zzInfo.buyJurisdiction3") }}</p>
          <p></p>
          <p v-if="level == 2">{{ $t("Cart.zzInfo.invite1") }}</p>
          <p v-if="level == 3">{{ $t("Cart.zzInfo.invite4") }}</p>
          <p v-if="level == 4">{{ $t("Cart.zzInfo.invite3") }}</p>
          <p></p>
          <div class="btn-box">
            <button @click="handleInviteFriends">
              {{ $t("Cart.zzInfo.invite2") }}
            </button>
          </div>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <div class="buy-fail-not-money" v-if="isBuyFailNotMoneyShow">
      <div class="cont">
        <div>
          <p>{{ $t("Cart.zzInfo.myMoney") }}</p>
          <p>{{ $t("Cart.zzInfo.under") }}</p>
          <p>{{ $t("Cart.zzInfo.notSendMoney") }}</p>
          <p>{{ $t("Cart.zzInfo.goTopUp") }}</p>
          <p>{{ $t("Cart.zzInfo.know") }}</p>
          <button @click="handlecolsePopBox">
            {{ $t("Cart.zzInfo.account") }}
          </button>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <van-popup v-model="popupShow">
      <div class="popup">
        <p>{{ $t("Cart.hint.p1") }}</p>
        <p>{{ $t("Cart.hint.p2") }}</p>
        <p>{{ $t("Cart.hint.p3") }}</p>
      </div>
    </van-popup>
    <!-- <Tabbar active="cart" /> -->
  </div>
</template>

<script>
import {
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  Toast,
  Icon,
  // List,
  Cell,
  progress,
  Popup,
  NoticeBar,
  CountDown,
  Tabs,
  Tab,
  Sticky,
} from "vant";
import JNav from "@/components/JNav";
import Tabbar from "@/components/Tabbar";
import { domain, $get, $post } from "@/utils/request";
import { REQUEST_API } from "@/http/api";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
import { formatDate } from "@/utils/tools";
import verConfig from "@/utils/verConfig";
import { List } from "@tt/components"

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [progress.name]: progress,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [CountDown.name]: CountDown,
    Tabbar,
    Toast,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    JNav,
    Sticky,
    List
  },

  data() {
    this.statusData = [
      { title: this.$t("order.status.all"), value: 0 },
      { title: this.$t("order.status.waiting"), value: 1 },
      { title: this.$t("order.status.done"), value: 2 },
      // { title: "已关闭", value: 4 },
    ];
    return {
      tabActive: 0,
      verConfig,
      domain: domain,
      buyLoading: -1,
      listLoading: false,
      tableList: [
        {
          title: "V1",
        },
        {
          title: `2`,
        },
        {
          title: `3`,
        },
        {
          title: `4`,
        },
      ],
      statusBarList: [
        {
          label: `${this.$t("Cart.state.all")}`,
          key: null,
        },
        {
          label: `${this.$t("Cart.state.going")}`,
          key: "ing",
        },
        // {
        //   label: `${this.$t('Cart.state.paid')}`,
        //   key: 1,
        // },
        // {
        //   label: `${this.$t('Cart.state.frozen')}`,
        //   key: 5,
        // },
        {
          label: `${this.$t("Cart.state.over")}`,
          key: 2,
        },
      ],
      tableKey: 0,
      statusKey: null,
      moneyInfoList: [
        {
          label: `${this.$t("Cart.info.allMoney")}`,
          key: "allMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayAllOrder")}`,
          key: "dayAllOrder",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayTrustMoney")}`,
          key: "dayTrustMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.yesterdayMoney")}`,
          key: "yesterdayMoney",
          value: 0,
        },
      ],
      list: [],
      loading: false,
      finished: false,
      isBuyBoxShow: false,
      isBuyFailShow: false,
      isBuyFailNotMoneyShow: false,
      percentage: 0,
      level: 1,
      pageNo: 1,
      buyGoodsForm: {}, // 购买当前商品信息，可用可不用
      popupShow: false,
      interval: "",
      pageNow: 0,
      newList: [],
      userInfo: {},
      // merchants: [],
      tabTop: 0,
    };
  },

  computed: {
    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      );
    },
    merchants() {
      return this.$store.getters.AllGameList || [];
    }
  },
  created() {
    // this.getTaskOrder();
    this.getUserInfo();
    // this.merchants = JSON.parse(this.$cookie.get("merchants")) || [];
  },
  mounted() {
    this.initTitlePosition();
    this.tabTop = this.$refs.tabWrap.$el.offsetTop;
    console.log(this.tabTop);
  },
  methods: {
    async refresh() {
      return new Promise((resolve, reject) => {
        this.pageNow = 0;
        this.finished = false;
        this.getTaskOrder(
          (res) => {
            return resolve(res);
          },
          (err) => {
            return reject(err);
          }
        );
      });
    },
    // tab切换
    tabChange(name, title) {
      // this.pageNow = 1;
      // this.loading = true;
      // this.finished = false;
      // this.getTaskOrder();
      // this.refresh();
      this.$refs.listRef.handleRefresh({ status: this.tabActive });
    },
    toShoppingDetail(item) {
      if (item.url && item.url !== "") {
        window.open(item.url);
      }
    },
    dateEvent(indate) {
      return formatDate("", indate);
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          this.userInfo = res;
          Toast.clear();
        },
        (err) => {
          Toast.clear();
        }
      );
    },
    /** handle **/
    handleFindExplain() {
      this.popupShow = true;
    },
    handleTable(index) {
      this.listLoading = true;
      if (index === this.tabelKey) {
        return false;
      }
      this.tableKey = index;
      // this.getTaskOrder();
      // this.refresh();
    },
    statusSearch(item) {
      this.newList = [];
      this.listLoading = true;
      if (item.key === this.statusKey) {
        return false;
      }
      this.statusKey = item.key;
      // this.getTaskOrder();
      // this.refresh();
    },
    handleBuy(item) {
      if (this.userInfo.level) {
        if (this.userInfo.level >= this.tableKey) {
          this.isBuyBoxShow = true;
          this.percentage = 0;

          let auth_timetimer = setInterval(() => {
            this.percentage++;
            if (this.percentage > 30) {
              clearInterval(auth_timetimer);
            }
          }, 20);
          let auth_timetimer2 = setInterval(() => {
            if (this.percentage >= 31 && this.percentage <= 70) {
              this.percentage++;
            }
            if (this.percentage > 60) {
              clearInterval(auth_timetimer2);
            }
          }, 80);

          this.$api.getTask(
            { level: this.tableKey },
            (res) => {
              let auth_timetimer3 = setInterval(() => {
                if (this.percentage >= 61 && this.percentage < 100) {
                  this.percentage++;
                }
                if (this.percentage === 100) {
                  clearInterval(auth_timetimer3);
                  this.interval = setInterval(() => {
                    this.$api.getTask({ level: this.tableKey }, (result) => {
                      if (result.status == 1) {
                        // this.getTaskOrder();
                        // this.refresh();
                        clearInterval(this.interval);
                      }
                    });
                  }, 5000);
                  setTimeout(() => {
                    this.isBuyBoxShow = false;
                    this.$toast(
                      "Order has been obtained, please wait for the order!"
                    );
                  }, 300);
                }
              }, 30);
            },
            (err) => {
              let auth_timetimer3 = setInterval(() => {
                if (this.percentage >= 61 && this.percentage < 99) {
                  this.percentage++;
                }
                if (this.percentage === 99) {
                  clearInterval(auth_timetimer3);
                  setTimeout(() => {
                    this.isBuyBoxShow = false;
                    if (typeof err == "string") {
                      this.$toast(err + "!");
                    } else {
                      this.$toast("Network timeout, please try again later!");
                    }
                  }, 20000);
                }
              }, 100);
            }
          );
        } else {
          this.$toast("Grade mismatch!");
        }
      } else {
        this.$api.getUserInfo();
        this.handleBuy();
      }
    },
    handleBuyPercentage(item) {
      if (this.percentage < 100) {
        return false;
      }
      this.sendBuy(this.buyGoodsForm.id);
    },
    handleInviteFriends() {
      this.$router.push({ name: "InviteFriends" }); // 邀请好友
    },
    handleBuyVip() {
      this.isBuyFailShow = false;
      this.$router.push({ name: "Recharge" });
    },
    handlecolsePopBox() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    handleColesZZ() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    /** api **/
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        this.finished = true;
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    getGoodsList() {
      if (this.finished) {
        return false;
      }
      let form = {
        uid: this.userInfo[1].data.player.uid,
        level: this.level,
        cmd: "201002",
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.length) {
              this.list.push(...r.data.notifyList[0].data);
              this.finished = true;
              this.pageNo++;
              if (this.finished) {
                this.loading = false;
              }
            } else {
              this.list = [];
              this.loading = false;
              this.finished = true;
            }
          } else {
            Toast(this.$t("Hint.failed"));
            this.list = [];
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    getMoneyInfo() {
      let form = {
        uid: this.userInfo[1].data.player.uid,
        cmd: "201004",
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            let moneyForm = r.data.notifyList[0].data;
            this.moneyInfoList[0].value = deciTurnMoney(moneyForm.diamond) || 0;
            this.moneyInfoList[1].value = moneyForm.size || 0;
            this.moneyInfoList[2].value = deciTurnMoney(moneyForm.price) || 0;
            this.moneyInfoList[3].value =
              deciTurnMoney(moneyForm.last_reward) || 0;
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    sendBuy(id) {
      let form = {
        id,
        cmd: "201003",
        uid: this.userInfo[1].data.player.uid,
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            Toast(this.$t("Hint.success"));
            this.handleColesZZ();
            this.list = [];
            this.finished = false;
            this.pageNo = 1;
            this.getMoneyInfo();
          } else if (r.data.sc === 1002) {
            this.isBuyBoxShow = false;
            // this.isBuyFailShow = true
            this.isBuyFailNotMoneyShow = true;
            Toast(this.$t("Hint.failed"));
          } else if (r.data.sc === 1211) {
            Toast(this.$t("Hint.goodsNumNo"));
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    /** 其他 **/
    init() {
      this.userInfo = JSON.parse(localStorage.getItem("user-info"));
      this.level = this.$route.query.id ? Number(this.$route.query.id) + 1 : 1;
      this.tableList.forEach((_, i) => {
        if (i + 1 === this.level) {
          this.tabelKey = _.key;
        }
      });
    },
    initTitlePosition() {
      if (this.tableKey > 2) {
        let title_wrap = document.querySelector(".classify");
        title_wrap.scrollLeft = document.documentElement.clientWidth;
      }
    },
    getLevels() {
      this.$api.getLevel((res) => {
        this.tableList = res;
      });
    },
    closeBuy() {
      this.isBuyBoxShow = false;
    },
    // 状态判断
    getStatus(item) {
      if (!item) return;
      const { status, open } = item;
      switch (status) {
        case "待开奖":
          return this.$t("status.waiting");
          break;
        case "已开奖":
          const data = open["hz-hz-dxds"] || (open.dxds && open.dxds[0]) || {};
          // console.log(data, open["hz-hz-dxds"], open.dxds);
          let res = "";
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const value = data[key];
              res += "\n" + this.getStatusText(value);
            }
          }
          // res = this.getStatusText(data.dx);
          // res += "\n" + this.getStatusText(data.ds);
          return res;
          break;
        case "开奖出错":
          return this.$t("status.fail");
          break;
        default:
          return "";
          break;
      }
    },
    getStatusText(text) {
      switch (text) {
        case "大":
          return this.$t("open.big");
          break;
        case "小":
          return this.$t("open.small");
        case "单":
          return this.$t("open.single");
        case "双":
          return this.$t("open.double");
        default:
          return text;
      }
    },
    async getTaskOrder(success, fail) {
      // this.listLoading = true;
      if (this.finished) return;
      this.loading = true;
      this.pageNow++;
      let params = {
        // level: this.tableKey,
        page: this.pageNow,
        status: this.tabActive,
      };
      try {
        Toast.loading();
        const res = await $post("/lottery/order", params);
        const { ret, msg, data } = res.data;
        Toast.clear();
        if (this.pageNow == 1) {
          this.newList = data.lists;
        } else {
          this.newList = [...data.lists, ...this.newList];
          // for (let i = 0; i < data.lists.length; i++) {
          //   const item = data.lists[i];
          //   this.newList.push(item);
          // }
          // this.newList.concat(data.lists);
        }
        // this.listLoading = false;
        this.loading = false;
        this.finished = !data.hasMorePages;
        // if (data.currentPage == data.lastPage) {
        //   this.finished = true;
        // }
        // this.finished = true;
        if (success) {
          success(data);
        }
      } catch (error) {
        // this.listLoading = false;
        this.loading = false;
        // this.finished = true;
        if (fail) {
          fail(error);
        }
      }
    },
    toPay(row, index) {
      // this.newList[index].buyLoading = true;
      if (this.buyLoading !== index) {
        this.buyLoading = index;
        this.$api.payTaskOrder(
          row.id,
          (res) => {
            if (res.ret) {
              this.newList[index].status = true;
              this.$api.getUserInfo(
                () => { },
                () => { },
                () => {
                  this.userInfo.money = JSON.parse(
                    localStorage.getItem("userInfo")
                  ).money;
                }
              );
            } else {
              Toast(res.msg);
            }
            // this.refresh()
            // .then((res) => {
            //   this.buyLoading = -1;
            //   console.log("success--------------------");
            // })
            // .catch((err) => {
            //   console.log("fasil--------------------");
            //   this.$toast(err);
            // });
            // this.getTaskOrder(
            //   (res) => {
            //     this.buyLoading = -1;
            //     console.log("success--------------------");
            //   },
            //   (err) => {
            //     console.log("fasil--------------------");
            //     this.$toast(err);
            //   }
            // );
          },
          (err) => {
            if (err.ret == 0) {
              this.$toast(err.msg);
              setTimeout(() => {
                this.$router.push({ name: "NewRecharge" });
              }, 1000);
            } else {
              if (err.msg) {
                this.$toast(err.msg);
              }
            }
          }
        );
      }
    },
    toDetails({ id }) {
      // this.$router.push(`/OrderDetails?id=${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
// @Color: #e23d6d;
@Color: var(--theme);
@comRadius: 10px;
// @comBG: linear-gradient(#093958, #022c47);
@comBG: #fff;
@wrapPadding: calc(12rem / 16);

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.content {
  // height: calc(100vh - (50rem / 16));
  min-height: 100vh;
  background-color: var(--hallBg);
  overflow: hidden;

  .top_bar {
    background: var(--hallBg);
  }

  .ball_wrap {
    // margin-left: calc(-4rem / 16);

    // display: flex;
    .ball {
      text-indent: 0;
      text-align: center;
      border-radius: 50%;
      width: calc(26rem / 16);
      line-height: calc(26rem / 16);
      height: calc(26rem / 16);
      margin: 1px;
      background: var(--theme);
      color: #000;
      display: inline-block;
      background-image: url("~@/assets/icon/game_ball.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  >.title {
    color: var(--textColor);
    height: calc(49rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    background: @Color;
    font-weight: 400;
    font-size: calc(18rem / 16);
    position: relative;
    width: 100%;
    margin-bottom: calc(12rem / 16);
  }

  .money_info_wrap {
    background: var(--hallBg);
    overflow: hidden;
    padding: @wrapPadding @wrapPadding @wrapPadding;
    position: relative;
    min-height: calc(26rem / 16);

    >.line_center_wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      >.line_center {
        width: 1px;
        height: 80%;
        background-color: rgb(226, 226, 226);
      }
    }

    >.money_content {
      // width: 100%;
      // height: 100%;
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      align-items: center;
      background: @comBG;
      padding: calc(@wrapPadding * 1.5) 0;
      border-radius: @comRadius;
      background: linear-gradient(#093958, #022c47);

      >.money_item {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        >.item_content {
          padding: 0 calc(6rem / 16);
          text-align: center;
          line-height: 1.5;
          font-weight: bold;

          >.title {
            font-size: calc(14rem / 16);
            // color: #b8ecff;
            // color: var(--gray1);
            color: var(--light);
          }

          >.data {
            margin-top: calc(3rem / 16);
            font-size: calc(16rem / 16);
            color: @Color;
            color: var(--light);
          }
        }
      }
    }
  }

  >.hint {
    width: 100%;
    padding: calc(4rem / 16) 0;
    background-color: @Color;
    color: var(--textColor);
    text-align: center;
    font-size: calc(12rem / 16);
  }

  .placeholder {
    width: 100%;
    height: calc(12rem / 16);
    background-color: rgb(248, 248, 248);
  }

  /deep/ .van-tabs {
    .van-tabs__wrap {
      padding: @wrapPadding;
      background-color: var(--hallBg);

      .van-tabs__line {
        display: none;
      }

      .van-tabs__nav {
        // background: @comBG;
        background: transparent;

        .van-tab {
          @gap: calc(10rem / 16);
          width: calc((100% - @gap * 2) / 3);
          background: linear-gradient(90deg, #ffad17, #ffe28b);
          padding: 0;
          flex: none;
          margin-left: @gap;
          color: var(--textColor) !important;
          border-radius: 6px;

          &:nth-of-type(1) {
            margin-left: 0;
          }
        }

        .van-tab--active {
          color: var(--light) !important;
          background: linear-gradient(90deg, #62aaff, #b8daff);
        }
      }
    }
  }

  .goods {
    // width: 100%;
    // height: calc(145rem / 16);
    background: linear-gradient(#093958, #022c47);
    margin: @wrapPadding;
    border-radius: @comRadius;

    >.good_content {
      height: 100%;
      padding: 0 calc(16rem / 16);

      >.top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: calc(14rem / 16);
        font-weight: bold;
        padding: calc(8rem / 16) 0;

        >.price {
          color: @Color;
        }

        >.shop_name {
          color: #eaad5f;
        }

        >.tobuy {
          color: var(--textColor);
          padding: calc(6rem / 16) calc(6rem / 16);
          background-color: @Color;
          border-radius: 4px;
        }
      }

      >.bottom {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        padding: calc(8rem / 16) 0;
        line-height: 1;

        >.left {
          max-width: 40%;
          width: 24vw;
          height: 24vw;
          display: flex;
          justify-content: center;
          align-items: center;

          >img {
            width: 20vw;
            height: 20vw;
            border-radius: 2px;
          }
        }

        >.right {
          display: flex;
          flex-direction: column;
          // align-items: flex-end;
          justify-content: space-between;
          margin-left: calc(20rem / 16);
          // max-width: 60%;
          font-size: calc(14rem / 16);
          padding: calc(10rem / 16);
          flex-grow: 1;

          >div {
            margin-top: 4px;

            &:nth-child(1) {
              margin-top: 0;
            }
          }

          .label {
            // color: var(--grey);
            color: var(--gray3);
          }

          .value {
            color: var(--theme);
          }

          .price {
            // display: flex;
            // justify-content: space-between;
          }

          >.return {
            color: @Color;
            font-size: calc(14rem / 16);
            margin-bottom: calc(12rem / 16);
          }

          >.desc {
            color: #666;
            font-size: calc(12rem / 16);
            margin-bottom: calc(38rem / 16);
            max-width: 100%;
            word-break: break-all;
          }

          >.time {
            color: var(--gray3);
            font-size: calc(12rem / 16);
          }
        }
      }
    }

    >.vantActive {
      width: 100%;
    }
  }

  >.statusBar {
    // width: 98%;
    width: 100%;
    display: flex;
    margin: 0 auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;

    >div {
      width: 33.33%;
      height: calc(38rem / 16);
    }
  }

  >.buy-box {
    width: 100%;
    height: 100%;
    // position: relative;
    position: fixed;
    // top: -100%;
    top: 0;

    >.cont {
      width: calc(350rem / 16);
      height: calc(460rem / 16);
      // background: url('../../assets/Img/goods/img/DDSC_0001_xdz.png') no-repeat;
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(460rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0001_xdz.png") no-repeat;
        background-size: 100% 100%;

        >.goodsInfo {
          padding-top: calc(250rem / 16);
          display: flex;
          align-items: center;
          // justify-content: space-between;
          margin-bottom: calc(5rem / 16);
          position: relative;

          >.close {
            position: absolute;
            right: calc(10rem / 16);
            top: calc(120rem / 16);
          }

          >div {
            display: flex;
            align-items: center;
            font-size: calc(13rem / 16);
            color: #9b8383;

            >img {
              width: calc(30rem / 16);
              height: calc(30rem / 16);
              border-radius: 6px;
            }

            >div {
              display: flex;
              flex-direction: column;
              margin-left: calc(15rem / 16);
              width: calc(70rem / 16);

              >span {}
            }
          }

          >p {
            font-size: calc(18rem / 16);
            text-align: center;
            color: #9b8383;
            // margin-bottom: calc(2rem / 16);
            margin: 0 0 calc(2rem / 16) calc(15rem / 16);
          }
        }

        >div {
          width: 98%;
          margin-left: calc(6rem / 16);
          // margin: auto;
        }

        >span {
          font-size: calc(18rem / 16);
          display: block;
          text-align: center;
          color: #9b8383;
          // margin: calc(15rem / 16) calc(22rem / 16) calc(2rem / 16) 0;
          margin: calc(15rem / 16) 0 calc(2rem / 16) 0;
        }

        >button {
          width: calc(100% - (60rem / 16));
          height: calc(60rem / 16);
          margin: auto;
          display: block;
          // margin-right: calc(40rem / 16);
          border-radius: 30px;
          border: 0;
          color: var(--textColor);
          background: #c4cec9;
          font-size: calc(22rem / 16);
          font-weight: 200;

          &.on {
            background: #48db8d;
          }
        }
      }
    }
  }

  >.buy-fail {
    width: 100%;
    height: 100%;
    position: relative;
    top: -100%;

    >.cont {
      width: calc(350rem / 16);
      height: calc(350rem / 16);
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(350rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0000_sbdb.png") no-repeat;
        background-size: 100% 100%;
        color: #9b8383;

        >p {
          text-align: center;
          font-size: calc(17rem / 16);
        }

        >p:nth-child(1) {
          font-size: calc(30rem / 16);
          line-height: calc(75rem / 16);
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(2) {}

        >p:nth-child(3) {
          font-size: calc(14rem / 16);
          margin: calc(20rem / 16) 0 calc(4rem / 16);
        }

        >p:nth-child(4) {
          color: #e6a30c;
          margin-bottom: calc(15rem / 16);
          font-size: calc(14rem / 16);
        }

        >.btn-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          justify-content: center;
          width: 90%;
          margin: calc(28rem / 16) auto 0;

          >button {
            width: calc(130rem / 16);
            width: calc(260rem / 16);
            height: calc(55rem / 16);
            border-radius: 35px;
            border: 0;
            background: #48db8d;
            color: var(--textColor);
            font-size: calc(20rem / 16);
            font-weight: 200;
          }
        }
      }
    }
  }

  >.buy-fail-not-money {
    width: 100%;
    height: 100%;
    position: relative;
    top: -100%;

    >.cont {
      width: calc(350rem / 16);
      height: calc(350rem / 16);
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(350rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0000_sbdb.png") no-repeat;
        background-size: 100% 100%;
        color: #9b8383;

        >p {
          text-align: center;
          font-size: calc(20rem / 16);
        }

        >p:nth-child(1) {
          font-size: calc(30rem / 16);
          line-height: calc(80rem / 16);
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(2) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(3) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
          color: #ff0000;
        }

        >p:nth-child(4) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(5) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
          color: #ff0000;
        }

        >button {
          width: calc(100% - (60rem / 16));
          height: calc(60rem / 16);
          margin: calc(30rem / 16) auto 0;
          display: block;
          border-radius: 30px;
          border: 0;
          color: var(--textColor);
          background: #48db8d;
          font-size: calc(22rem / 16);
          font-weight: 200;
        }
      }
    }
  }

  .popup {
    width: 80vw;
    padding: calc(15rem / 16) calc(20rem / 16);
    font-size: calc(15rem / 16);

    >p {
      margin-bottom: calc(8rem / 16);
    }
  }

  .colon {
    display: inline-block;
    margin: 0 4px;
    color: #b36900;
  }

  .block {
    display: inline-block;
    width: 22px;
    color: var(--textColor);
    font-size: 12px;
    text-align: center;
    background-color: #b36900;
  }
}
/deep/ .nodata{
  background-color: var(--hallBg);
}
</style>
